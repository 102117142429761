import React from "react";
import { Paper, Button, Card, CardMedia, CircularProgress, Typography, Grid, Box } from "@mui/material";
import { FiPlus } from "react-icons/fi";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { UnassigenedUplaodVideoLoader } from "../../components/common/loader/Loader";
import { useVideos } from "./hooks/useVideos"; // Import the custom hook

const VideoPagination = () => {
  const {
    videos,
    loading,
    isNext,
    handleNextPage,
    handlePrevPage,
    handleUpload,
    uploading,
    handleVideoPlay,
    videoRefs,
    VIDEO_BASE_URL,
  } = useVideos();

  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <Paper
        sx={{
          boxShadow: "none !important",
          borderRadius: "12px",
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "divider",
          p: "20px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "20px" }}>
          <Typography variant="h6">Videos</Typography>
          <Box display="flex" gap={2}>
            <Button
              sx={{ borderRadius: "20px" }}
              variant="contained"
              color="primary"
              onClick={handleUpload}
              disabled={uploading}
            >
              {uploading ? (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CircularProgress size={20} sx={{ color: "black", mr: 1 }} />
                  Publishing...
                </Box>
              ) : (
                "Publish Video"
              )}
            </Button>

            <Link to="/video-unassigned/upload-video" style={{ textDecoration: "none" }}>
              <Button variant="contained" color="primary" startIcon={<FiPlus />} sx={{ borderRadius: "20px" }}>
                Upload Unassigned Video
              </Button>
            </Link>

            <Link to="/video-unpublish/upload-unpublish-videos" style={{ textDecoration: "none" }}>
              <Button variant="contained" color="primary" startIcon={<FiPlus />} sx={{ borderRadius: "20px" }}>
                Upload Unpublished Video
              </Button>
            </Link>
          </Box>
        </Box>

        {loading ? (
          <UnassigenedUplaodVideoLoader />
        ) : (
          <Box>
            <Grid container spacing={2}>
              {videos.length > 0 ? (
                videos.map((video, index) => (
                  <Grid item xs={12} sm={6} md={4} key={video.Key || index}>
                    <Card>
                      <CardMedia
                        component="video"
                        src={`${VIDEO_BASE_URL}/${video.Key}`}
                        controls
                        style={{ height: "200px", borderRadius: "10px", border: "1px solid #ddd", width: "100%" }}
                        onPlay={() => handleVideoPlay(index)}
                        ref={(el) => (videoRefs.current[index] = el)}
                      />
                    </Card>
                  </Grid>
                ))
              ) : (
                <Grid item xs={12} sx={{ textAlign: "center", mt: 3 }}>
                  <Typography variant="h6" color="gray">
                    No videos available to display.
                  </Typography>
                </Grid>
              )}
            </Grid>

            <Box display="flex" justifyContent="flex-end" mt={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={handlePrevPage}
                disabled={videos.length === 0 || loading}
                sx={{ borderRadius: "20px" }}
              >
                Previous
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNextPage}
                disabled={!isNext || loading}
                style={{ marginLeft: "8px", borderRadius: "20px" }}
              >
                Next
              </Button>
            </Box>
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default VideoPagination;



// const VideoPagination = () => {
//   const [videos, setVideos] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [nextKey, setNextKey] = useState(null);
//   const [prevKeys, setPrevKeys] = useState([]);
//   const [isNext, setIsNext] = useState(true);
//   const [uploading, setUploading] = useState(false);
//   const videoRefs = useRef([]);

//   const token = localStorage.getItem("token");

//   const fetchVideos = async (pageKey = null) => {
//     setLoading(true);
//     try {
//       const encodedKey = pageKey ? encodeURIComponent(pageKey) : null;
//       const response = await axios.get(API_URL, {
//         params: {
//           pageSize: 3,
//           nextKey: encodedKey,
//         },
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });

//       const { list, isNext: hasNext, nextkey } = response.data;
//       setVideos(list);
//       setIsNext(hasNext);
//       setNextKey(nextkey);

//       if (!pageKey) {
//         setPrevKeys([]); // Reset previous keys if starting fresh
//       }
//     } catch (error) {
//       console.error("Error fetching videos:", error);
//       toast.error("Failed to load videos. Please try again later.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleUpload = async () => {
//     setUploading(true);
//     try {
//       const response = await axios.get(UPLOAD_API_URL, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });

//       const message = response.data?.message || "Video Publish successfully.";
//       toast.success(message);
//       fetchVideos(); // Refresh the video list after upload
//     } catch (error) {
//       console.error("Error uploading video:", error);
//       toast.error("Failed to upload video. Please try again.");
//     } finally {
//       setUploading(false);
//     }
//   };

//   useEffect(() => {
//     fetchVideos();
//   }, []);



//   const handleVideoPlay = (index) => {
//     videoRefs.current.forEach((video, i) => {
//       if (video && i !== index) {
//         video.pause();
//       }
//     });
//   };

//   const handleNextPage = () => {
//     if (isNext && nextKey) {
//       setPrevKeys((prev) => [...prev, nextKey]);
//       fetchVideos(nextKey);
//     }
//   };

//   const handlePrevPage = () => {
//     const lastKey = prevKeys[prevKeys.length - 1];
//     if (lastKey) {
//       const updatedPrevKeys = [...prevKeys];
//       updatedPrevKeys.pop();
//       setPrevKeys(updatedPrevKeys);
//       fetchVideos(updatedPrevKeys[updatedPrevKeys.length - 1] || null);
//     }
//   };

//   return (
//     <Box sx={{ pt: "80px", pb: "20px" }}>
//       <Paper
//         sx={{
//           boxShadow: "none !important",
//           borderRadius: "12px",
//           borderStyle: "solid",
//           borderWidth: "1px",
//           borderColor: "divider",
//           p: "20px",
//         }}
//       >
//         <Box
//           sx={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "space-between",
//             marginBottom: "20px",
//           }}
//         >
//           <Typography variant="h6">Videos</Typography>
//           <Box display="flex" gap={2}>
       

//               <Button
//                         sx={{ borderRadius: "20px" }}
//                         variant="contained"
//                         color="primary"
//                         onClick={handleUpload}
//                         disabled={uploading}
//                       >
//                         {uploading ? (
//                           <Box sx={{ display: "flex", alignItems: "center" }}>
//                             <CircularProgress size={20} sx={{ color: "black", mr: 1 }} />
//                             Publishing...
//                           </Box>
//                         ) : (
//                           "Publish Video"
//                         )}
//                       </Button>
                     
//             <Link to="/video-unassigned/upload-video" style={{ textDecoration: "none" }}>
//               <Button
//                 variant="contained"
//                 color="primary"
//                 startIcon={<FiPlus />}
//                 sx={{ borderRadius: "20px" }}
//               >
//                 Upload Video
//               </Button>
//             </Link>
//           </Box>
//         </Box>


//         {loading ? (
//           <UnassigenedUplaodVideoLoader />
//         ) : (
// <Box>
//             <Grid container spacing={2}>
//               {videos.length > 0 ? (
//                 videos.map((video, index) => (
//                   <Grid item xs={12} sm={6} md={4} key={video.Key}>
//                     <Card>
//                       <CardMedia
//                         component="video"
//                         src={`${VIDEO_BASE_URL}${video.Key}`}
//                         controls
//                         style={{ height: "200px", borderRadius: "10px" , border: "1px solid #ddd"}}
//                         onPlay={() => handleVideoPlay(index)}
//                         ref={(el) => (videoRefs.current[index] = el)}
//                       />
//                     </Card>
//                   </Grid>
//                 ))
//               ) : (
//                 <Typography>No videos available to display.</Typography>
//               )}
//             </Grid>

//             <Box display="flex" justifyContent="flex-end" mt={2}>
//   <Button
//     variant="contained"
//     color="primary"
//     onClick={handlePrevPage}
//     disabled={prevKeys.length === 0 || loading}
//     sx={{ borderRadius: "20px" }}
//   >
//     Previous
//   </Button>
//   <Button
//     variant="contained"
//     color="primary"
//     onClick={handleNextPage}
//     disabled={!isNext || loading}
//     style={{ marginLeft: '8px' ,borderRadius: "20px"}} // Optional: Add some space between buttons
//   >
//     Next
//   </Button>
// </Box>
//           </Box>
//            )}
//       </Paper>
//     </Box>
//   );
// };

// export default VideoPagination;










// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import {
//   Box,
//   Grid,
//   Card,
//   CardMedia,
//   CircularProgress,
//   Typography,
//   Button,
// } from "@mui/material";

// const VideoList = () => {
//   const [videos, setVideos] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [nextKey, setNextKey] = useState(null);
//   const [prevKeys, setPrevKeys] = useState([]);
//   const [isNext, setIsNext] = useState(false);

//   const API_URL =
//     "https://s2lieet7xb.execute-api.ap-south-1.amazonaws.com/dev/list/video/unassigned";
//   const VIDEO_BASE_URL =
//     "https://bloopers-unassigned-videos.s3.ap-south-1.amazonaws.com/";

//   // Fetch videos from the API
//   const fetchVideos = async (pageKey = null) => {
//     setLoading(true);
//     setError(null);

//     try {
//       // Retrieve token from localStorage
//       const token = localStorage.getItem("token");

//       if (!token) {
//         throw new Error("Authentication token is missing. Please log in.");
//       }
//       const encodedKey = pageKey ? encodeURIComponent(pageKey) : null;
//       const response = await axios.get(API_URL, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//         params: {
//           pageSize: 3,
//           nextKey: encodedKey, // 
//         },
//       });

//       const { list, isNext, nextkey } = response.data;

//       // Track previous keys for backward navigation
//       // if (pageKey && !prevKeys.includes(pageKey)) {
//       //   setPrevKeys([...prevKeys, pageKey]);
//       // }
//       setVideos(list); // Update videos array
//       setIsNext(isNext); // Update `isNext`
//       setNextKey(nextkey); // Update `nextKey`
  
//       // Log the updated state
//       console.log("Updated videos:", list);
//       console.log("Updated nextKey:", nextkey);
//       console.log("Updated prevKeys:", prevKeys);
//     } catch (err) {
//       setError(err.response?.data?.message || "An error occurred while fetching videos.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     // Initial fetch
//     fetchVideos();
//   }, []);

//   const loadPreviousVideos = () => {
//     if (prevKeys.length > 1) {
//       const previousKey = prevKeys[prevKeys.length - 2];
//       setPrevKeys(prevKeys.slice(0, -1));
//       fetchVideos(previousKey);
//     }
//   };

//   const loadNextVideos = () => {
//     if (nextKey) {
//       fetchVideos(nextKey);
//     }
//   };

//   return (
//     <Box p={4} mt={6}>
//       <Typography variant="h5" gutterBottom>
//         Video Gallery
//       </Typography>

//       {loading && (
//         <Box display="flex" justifyContent="center" my={2}>
//           <CircularProgress />
//         </Box>
//       )}

//       {error && (
//         <Typography color="error" gutterBottom>
//           {error}
//         </Typography>
//       )}

//       <Grid container spacing={3} mt={2}>
//         {videos.map((video, index) => (
//           <Grid item xs={12} sm={6} md={4} key={index}>
//             <Card>
//               <CardMedia
//                 component="video"
//                 controls
//                 src={`${VIDEO_BASE_URL}${video.Key}`}
//                 title={video.Key}
//                 style={{ height: "200px" }}
//               />
//             </Card>
//           </Grid>
//         ))}
//       </Grid>

//       <Box display="flex" justifyContent="flex-end" mt={2} gap={2}>
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={loadPreviousVideos}
//           disabled={prevKeys.length <= 1 || loading}
//         >
//           Previous
//         </Button>
//         <Button
//           variant="contained"
//           color="secondary"
//           onClick={loadNextVideos}
//           disabled={!isNext || loading}
//         >
//           Next
//         </Button>
//       </Box>
//     </Box>
//   );
// };

// export default VideoList;




