import { useState, useEffect, useRef } from "react";
import { getResponse } from "../../../utils/axios_api_utils";

const useLanguages = () => {
  const [languages, setLanguages] = useState([]);
  const [loading, setLoading] = useState(true);
  const isFetched = useRef(false); // Prevents multiple API calls

  useEffect(() => {
    if (isFetched.current) return; // Prevents duplicate calls
    isFetched.current = true; // Mark as fetched

    const fetchLanguages = async () => {
      setLoading(true);
      try {
        const response = await getResponse("general", "getLanguages");
        if (response.status === 200) {
          setLanguages(response.data.data);
        } else {
          console.error(`Error: ${response.status}`);
        }
      } catch (err) {
        console.error("Failed to fetch languages:", err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchLanguages();
  }, []); // Empty dependency array ensures it runs only once

  return { languages, loading };
};

export default useLanguages;
