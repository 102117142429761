import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("#"); // Adjust the path based on your app's home/dashboard route
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        textAlign: "center",
        backgroundColor: "#f9f9f9",
        padding: 3,
      }}
    >
      <Typography
        variant="h1"
        component="div"
        sx={{ fontSize: { xs: "3rem", md: "5rem" }, fontWeight: 700, color: "#555" }}
      >
        404
      </Typography>
      <Typography variant="h5" sx={{ mb: 2, color: "#666" }}>
        Oops! The page you're looking for doesn't exist.
      </Typography>
      <Typography variant="body1" sx={{ mb: 4, color: "#999" }}>
        It might have been removed or the URL may be incorrect.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleGoHome}
        sx={{
          textTransform: "none",
          fontSize: "1rem",
          padding: "10px 20px",
        }}
      >
        Go to Home
      </Button>
    </Box>
  );
};

export default NotFound;
