import React, { useState,useRef } from "react";
import { Box,Button, Grid, Paper, Typography, IconButton, CircularProgress,} from "@mui/material";
import { useDropzone } from "react-dropzone";
import ClearIcon from "@mui/icons-material/Clear";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ReactPlayer from "react-player";
import { toast } from "react-toastify";
import { postResponse } from "../../../utils/axios_api_utils";

const FileUpload = ({ userId,onUploadSuccess }) => {
  const [thumbnails, setThumbnails] = useState({});
  const [thumbnailIds, setThumbnailIds] = useState({});
  const [files, setFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const videoRefs = useRef([]);

  // Generate high-quality thumbnails
  const generateThumbnails = async (videos) => {
    for (const video of videos) {
      if (video.type.startsWith("video")) {
        const videoElement = document.createElement("video");
        videoElement.src = URL.createObjectURL(video);

        await new Promise((resolve) => {
          videoElement.onloadeddata = () => {
            const canvas = document.createElement("canvas");
            canvas.width = videoElement.videoWidth;
            canvas.height = videoElement.videoHeight;

            const ctx = canvas.getContext("2d");
            videoElement.currentTime = 2; // Capture a frame at 2-second mark

            videoElement.onseeked = () => {
              ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
              const thumbnail = canvas.toDataURL("image/jpeg", 0.9); // High-quality JPEG
              setThumbnails((prev) => ({ ...prev, [video.name]: thumbnail }));
              resolve();
            };
          };
        });
      }
    }
  };

  // Handle file drop
  const onDrop = (acceptedFiles) => {
    const videoFiles = acceptedFiles.filter(file => file.type.startsWith("video"));    
    if (videoFiles.length === 0) {
      toast.warning("Only video files are allowed.");
      return;
    }  
    if (files.length + videoFiles.length > 10) {
      toast.error("You can only upload up to 10 files.");
      return;
    }
     setFiles((prevFiles) => {
      const updatedFiles = [...prevFiles, ...videoFiles];
      generateThumbnails(videoFiles); // Generate thumbnails for video files
      return updatedFiles;
    });
  };

  // Handle file removal
  const handleRemoveFile = (fileToRemove) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove));
    setThumbnails((prev) => {
      const updatedThumbnails = { ...prev };
      delete updatedThumbnails[fileToRemove.name];
      return updatedThumbnails;
    });
  };

  // Upload thumbnails and videos

  const handleUploadFiles = async () => {
    if (files.length === 0) {
      toast.warn("No files selected for upload.");
      return;
    }
    setIsUploading(true);
    let allUploadsSuccessful = true; // Flag to track upload success
  
    try {
      for (const file of files) {
        let thumbnailId = null; 
        // Upload thumbnail (if available)
        if (thumbnails[file.name]) {
          const formDataThumb = new FormData();
          const blob = await (await fetch(thumbnails[file.name])).blob();
          formDataThumb.append("file", blob, `${file.name}.jpg`);
          const responseThumb = await postResponse('base', `upload/video/thumb/${userId}`,
            formDataThumb,
            { headers: { "Content-Type": "multipart/form-data" } }
          );
  
          if (responseThumb.status === 200 && responseThumb.data[0]) {
            thumbnailId = responseThumb.data[0];
            setThumbnailIds((prev) => ({ ...prev, [file.name]: thumbnailId }));
          } else {
            allUploadsSuccessful = false; // Mark as failed if thumbnail upload fails
            break; // Exit the loop on error
          }
        }
  
        // Upload video file
        const formDataVideo = new FormData();
        formDataVideo.append("file", file);
        if (thumbnailId) {
           formDataVideo.append("thumbnail", thumbnailId)}
          const response = await postResponse('base', `upload/user/video/${userId}`, formDataVideo,
          { headers: { "Content-Type": "multipart/form-data" }});
        if (response.status !== 200) {
          allUploadsSuccessful = false; // Mark as failed if video upload fails
          break; // Exit the loop on error
        }}
     // Show success toast if all files uploaded successfully
      if (allUploadsSuccessful) {
        toast.success("All files uploaded successfully!");
        setFiles([]); // Clear the file list
        onUploadSuccess(); // Callback function (if any)
      } else {
        toast.error("Some files failed to upload. Please try again.");
      }
    } catch (error) {
      console.error("Error during upload:", error);
      toast.error("Failed to upload files. Please try again.");
    } finally {
      setIsUploading(false);
    }
  };

  const handlePlayVideo = (index) => {
    videoRefs.current.forEach((player, i) => {
      if (player && i !== index && player.getInternalPlayer) {
        const internalPlayer = player.getInternalPlayer();
        if (internalPlayer.pause) internalPlayer.pause();
      }
    });
  };

  
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "video/*",
    maxFiles: 10 - files.length,
    multiple: true,
  });
  return (
    <Paper sx={{ padding: 2, borderRadius: "12px" }}>
      <Box sx={{ padding: 2 }}>
      <Typography variant="subtitle1" gutterBottom>File Upload ( Max 10 video files)</Typography>
        <Typography variant="body1" sx={{fontWeight:"bold"}} gutterBottom> Upload  Videos </Typography>   
        <Box
          {...getRootProps()}
          sx={{
            border: "2px dashed #ccc",
            padding: 3,
            textAlign: "center",
            cursor: "pointer",
            borderRadius: 2,
            transition: "all 0.3s",
            "&:hover": { borderColor: "primary.main",color: "primary.main", boxShadow: 3, transform: "scale(1.01)" },
          }}
        >
          <input {...getInputProps()} />
          <IconButton color="primary">
            <CloudUploadIcon sx={{ fontSize: 60, color: "primary.main" }} />
          </IconButton>
          <Typography variant="body1">Drag & Drop video files here or click to select files</Typography>
        </Box>
        <Grid container spacing={2} sx={{ marginTop: 2 }}>
          {files.map((file, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Box
                sx={{
                  position: "relative",
                  border: "1px solid #ddd",
                  // padding: 1,
                  // borderRadius: 2,
                  // boxShadow: 3,
                  "&:hover": { boxShadow: 3},
                }}>
                <ReactPlayer
                  url={URL.createObjectURL(file)}
                  onPlay={() => handlePlayVideo(index)}
                  ref={(el) => (videoRefs.current[index] = el)}
                  controls
                  width="100%"
                  height="200px"
                  sx={{ borderRadius: "10px" }}
                />
                {/* <Typography variant="body2" sx={{ mt: 1, textAlign: "center" }}>{file.name}</Typography> */}
                <IconButton
                  color="error"
                  onClick={() => handleRemoveFile(file)}
                  sx={{ position: "absolute", top: 4, right: 4 }}>
                  <ClearIcon />
                </IconButton>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ textAlign: "center", justifyContent: "center", mt: 3 }}>
          <Button
            variant="contained"
            onClick={handleUploadFiles}
            disabled={isUploading || files.length === 0}
            sx={{ borderRadius: "20px" }} >
            {isUploading ? <CircularProgress size={24} /> : "Upload Files"}
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};
export default FileUpload;