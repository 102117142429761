import React from "react";
import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import Table from "../../components/common/table/Table";
import useUnpublishVideos from "./hook/useUnpublishVideos"; // Import the custom hook
import { UnpublishVideosColumns } from "../../data/unPublishVideos";

// Styled wrapper for consistent layout.
const ComponentWrapper = styled(Box)({
  marginTop: "10px",
  paddingBottom: "10px",
});


const UnpublishVideos = () => {
  const { videos, loading } = useUnpublishVideos(); // Use the custom hook

  return (
    <Box sx={{ pt: "60px", pb: "20px" }}>
      <ComponentWrapper>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            my: 4,
          }}
        >
          <Typography variant="h6">Unpublish Video</Typography>
        </Box>

        <Table
          isLoading={loading}
          data={videos}
          fields={UnpublishVideosColumns}
          numberOfRows={7}
          enableTopToolBar={false}
          enableBottomToolBar={false}
          enablePagination={true}
          enableRowSelection={false}
          enableColumnFilters={false}
          enableEditing={false}
          enableColumnDragging={false}
        />
      </ComponentWrapper>
    </Box>
  );
};

export default UnpublishVideos;