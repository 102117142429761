import React from "react";
import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import Table from "../../components/common/table/Table";
import {  languageColumns } from "../../data/language";
import useLanguages from "./hooks/useLanguages";

const ComponentWrapper = styled(Box)({
    marginTop: "10px",
    paddingBottom: "10px",
  });

const Languages = () => {
const { languages, loading } = useLanguages();

  return (
    <Box sx={{ pt: "60px", pb: "20px" }}> 
      <ComponentWrapper>
        <Typography variant="h6" sx={{ my: 3 }}>
          Language
        </Typography>
        <Table
          isLoading={loading}
          data={languages}
          fields={languageColumns}
          numberOfRows={5}
          enableTopToolBar={false}
          enableBottomToolBar={false}
          enablePagination={false}
          enableRowSelection={false}
          enableColumnFilters={false}
          enableEditing={false}
          enableColumnDragging={false}/>
      </ComponentWrapper>
    </Box>
  );
};

export default Languages;

