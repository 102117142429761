  // Define table columns for video categories.
  export const categoryColumns = [
    {
      Header: "Name",
      accessorKey: "name",
    },
   
    {
        accessorKey: "status",
        header: "Status",
        Cell: ({ cell }) => {
          const status = cell.getValue();
          return (
            <span className={`status ${status ? "active" : "inactive"}`}>
              {status ? "Active" : "Inactive"}
            </span>
          );
        },
      },
    
      {
        Header: "Created At",
        accessorKey: "createdAt",
        Cell: ({ cell }) =>
          cell.getValue() ? new Date(cell.getValue()).toLocaleDateString() : "N/A",
      },
    
      {
        Header: "Updated At",
        accessorKey: "updatedAt",
        Cell: ({ cell }) =>
          cell.getValue() ? new Date(cell.getValue()).toLocaleDateString() : "N/A",
      },
  ];
