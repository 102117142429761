import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getResponse } from "../../../utils/axios_api_utils"; // Replace with your API utility
import CustomModal from "./CustomModal";

const FollowingDialog = ({ open, handleClose, userId }) => {
  const [followings, setFollowings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  // Fetch followings
  const fetchFollowings = async () => {
    setIsLoading(true);
    setError("");
    try {
      const response = await getResponse("base",`user/followings/${userId}`);
      if (response?.status === 200 ) {
        setFollowings(response.data.Items || []); // Assumes followings are in `Items`
      } else {
        throw new Error("Invalid response from server.");
      }
      
    } catch (err) {
      setError(err.response?.data?.message || "Failed to fetch followings.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      fetchFollowings();
    }
  }, [open]);

  return (
    <CustomModal
      open={open}
      handleClose={handleClose}
      title="Following"
      searchPlaceholder="Search followings..."
      emptyMessage="No followings to display."
      data={followings}
      isLoading={isLoading}
      errorMessage={error}
    />
  );
};

FollowingDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
};

export default FollowingDialog;
