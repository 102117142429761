import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getResponse } from "../../../utils/axios_api_utils"; // Replace with your actual API utility
import CustomModal from "./CustomModal";

const BlockUserDialog = ({ open, handleClose, userId }) => {
  const [blockedUsers, setBlockedUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  // Fetch blocked users
  const fetchBlockedUsers = async () => {
    setIsLoading(true);
    setError("");
    try {
      const response = await getResponse( "base", `/user/blocked/list/${userId}`);
      if (response?.status === 200 ) {
        setBlockedUsers(response.data.Items || []);
      } else {
        throw new Error("Invalid response from server.");
      }
    } catch (err) {
      setError(err.response?.data?.message || "Failed to fetch blocked users.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      fetchBlockedUsers();
    }
  }, [open]);

  return (
    <CustomModal
      open={open}
      handleClose={handleClose}
      title="Blocked Users"
      searchPlaceholder="Search blocked users..."
      emptyMessage="No blocked users to display."
      data={blockedUsers}
      isLoading={isLoading}
      errorMessage={error}
    />
  );
};

BlockUserDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
};

export default BlockUserDialog;
