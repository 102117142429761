import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import SignIn from "../../pages/auth/sign-in/SignIn";
import VerifyForgetPasswordOTP from "../../pages/auth/forget-password/verifyForgetPasswordOTP";
import ResetPassword from "../../pages/auth/forget-password/ResetPassword";
import ProtectedRoute from "./ProtectedRoute";
import LayoutWrapper from "../layout/LayoutWrapper";
// import PublicRoute from "./PublicRoute";
import NotFound from "../common/not-found/NotFound";

import {
  Dashboard,
  Users,
  SingleUser,
  Languages,
  HashTags,
  ReportReasons,
  Categories,
  VideoUnassigned,
  UploadVideo,
  VideoCategory,
  CreateUser,
  CreateCategory,
  UnpublishVideos,
  UploadUnpublishVideos,
  VideoPublished,
} from "../../pages";

const AdminRoutes = () => {
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/" element={<Navigate to="/login" replace />} />
      <Route path="/login" element={<SignIn />} />
      <Route path="/verify-forgot-password-otp" element={<VerifyForgetPasswordOTP />} />
      <Route path="/reset-password" element={<ResetPassword />} />

      {/* Protected Routes */}
      <Route element={<ProtectedRoute><LayoutWrapper /></ProtectedRoute>}>
        {/* Dashboard */}
        <Route path="/dashboard" element={<Dashboard />} />

        {/* User Management */}
        <Route path="/users" element={<Users />} />
        <Route path="/users/create-user" element={<CreateUser />} />
        <Route path="/users/:id" element={<SingleUser />} />

        {/* Content Management */}
        <Route path="/languages" element={<Languages />} />
        <Route path="/hashTags" element={<HashTags />} />
        <Route path="/report-reason" element={<ReportReasons />} />
        <Route path="/category" element={<Categories />} />
        <Route path="/video-category" element={<VideoCategory />} />
        <Route path="/video-category/create-category" element={<CreateCategory />} />

        {/* Video Management */}
        <Route path="/video-unassigned" element={<VideoUnassigned />} />
        <Route path="/video-unassigned/upload-video" element={<UploadVideo />} />
        <Route path="/video-published" element={<VideoPublished />} />
        <Route path="/video-unpublish" element={<UnpublishVideos />} />
        <Route path="/video-unpublish/upload-unpublish-videos" element={<UploadUnpublishVideos />} />
      </Route>

      {/* Catch-All Route */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AdminRoutes;



