import { useState, useEffect, useRef } from "react";
import { getResponse } from "../../../utils/axios_api_utils";

const useVideoCategories = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const isFetched = useRef(false); // Prevent multiple API calls.

  useEffect(() => {
    if (isFetched.current) return; // Prevent duplicate API calls.
    isFetched.current = true;
    const fetchCategories = async () => {
      setLoading(true);
      try {
        const response = await getResponse("base", "list/video/category");
        if (response.status === 200 ) {
          setCategories(response.data || []);
        }
      } catch (error) {
        console.error("Failed to fetch video categories:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();

    
  }, []);

  return { categories, loading };
};

export default useVideoCategories;
