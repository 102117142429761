import {
  Box,
  Button,
  Paper,
  TextField,
  Typography,
  CircularProgress,
  Grid,
} from "@mui/material";
import React from "react";
import Breadcrumb from "../../components/common/Breadcrumb/Breadcrumb";
import { useCreateUser } from "./hook/useCreateUser";

const CreateUser = () => {
  const { formData, loading, errors, handleChange, handleSubmit, resetForm } = useCreateUser();

  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <Breadcrumb />
      <Paper
        sx={{
          boxShadow: "none !important",
          borderRadius: "12px",
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "divider",
          p: "20px",
          margin: "0 auto",
          cursor: "pointer",
          overflow: "hidden",
        }}>
        <Typography variant="h6" sx={{ marginBottom: "14px" }}>
          Create User
        </Typography>

        <Grid container spacing={2}>
          {[
            { label: "First Name", name: "firstName" },
            { label: "Last Name", name: "lastName" },
            { label: "Email or Phone", name: "email_phone" },
            { label: "Device ID", name: "device_id" },
            { label: "Password", name: "password", type: "password" },
          ].map(({ label, name, type = "text" }, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <TextField
                label={label}
                name={name}
                type={type}
                variant="outlined"
                size="small"
                fullWidth
                required
                value={formData[name]}
                onChange={handleChange}
                error={!!errors[name]}
                helperText={errors[name]}
              />
            </Grid>
          ))}
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: "30px" }}>
          <Button
            variant="outlined"
            sx={{ borderRadius: "20px", marginRight: "10px" }}
            onClick={resetForm}
            disabled={loading}>
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ borderRadius: "20px" }}
            onClick={handleSubmit}
            disabled={loading}>
            {loading ? <CircularProgress size={20} color="inherit" /> : "Submit"}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default CreateUser;
