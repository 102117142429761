
  // Common function to format date
  const formatDate = (timestamp) => {
    return new Date(timestamp).toLocaleString("en-IN", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZone: "Asia/Kolkata",
    });
  };

  // Define table columns for unpublished videos.
  export const UnpublishVideosColumns = [
    {
      Header: "First Name",
      accessorKey: "firstName",
    },
    {
      Header: "Last Name",
      accessorKey: "lastName",
    },
    {
      Header: "User Name",
      accessorKey: "userName",
    },
    {
      Header: "Likes",
      accessorKey: "likes",
    },
    {
      Header: "Comment",
      accessorKey: "comments",
    },
    {
      Header: "Views",
      accessorKey: "views",
    },
    {
      Header: "Shares",
      accessorKey: "shares",
    },
    {
      Header: "Downloads",
      accessorKey: "downloads",
    },
    {
      Header: "Created At",
      accessorKey: "createdAt",
      Cell: ({ cell }) => formatDate(cell.getValue()),
    },
    {
      Header: "Updated At",
      accessorKey: "updatedAt",
      Cell: ({ cell }) => formatDate(cell.getValue()),
    },
    {
      accessorKey: "status",
      header: "Status",
      Cell: ({ cell }) => (
        <span
          className={`status ${cell.getValue() === 1 ? "active" : "inactive"}`}
        >
          {cell.getValue() === 1 ? "Active" : "Inactive"}
        </span>
      ),
    },
  ];