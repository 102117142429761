// import React from "react";
// import { Box, Typography } from "@mui/material";

// const Loader = () => {
//   const Name = "bloopers...";

//   return (
//     <Box display="flex" justifyContent="center" alignItems="center" height="100vh" bgcolor="background.default">
//       {Name.split("").map((letter, index) => (
//         <Typography
//           key={index}
//           variant="h4"
//           component="span"
//           sx={{
//             display: "inline-block",
//             animation: `wave 1.5s infinite`,
//             animationDelay: `${index * 0.1}s`,
//           }}
//         >
//           {letter}
//         </Typography>
//       ))}
//       <style>
//         {`
//           @keyframes wave {
//             0%, 60%, 100% {
//               transform: translateY(0);
//             }
//             30% {
//               transform: translateY(-10px);
//             }
//           }
//         `}
//       </style>
//     </Box>
//   );
// };

// export default Loader;



// import React from "react";
// import { Box, CircularProgress } from "@mui/material";

// const Loader = () => {
//   return (
//     <Box
//       display="flex"
//       justifyContent="center"
//       alignItems="center"
//       height="100vh"
//       bgcolor="background.default"
//     >
//       <CircularProgress
//         size={50}
//         sx={{ color: "black", }}
//       />
//     </Box>
//   );
// };

// export default Loader;


// material-ui
import React from "react";
import { styled } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Card from "@mui/material/Card";
import {
  Paper,
  Typography,
  Stack,
} from "@mui/material";
// Loader style
const LoaderWrapper = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 2001,
  width: '100%',
  '& > * + *': {  marginTop: theme.spacing(2)},
}));

// ==============================|| Loader ||============================== //
const Loader = () => (
  <LoaderWrapper>
    <LinearProgress color="primary" />
  </LoaderWrapper>
);

// ==============================|| Grid Loading Skeleton ||============================== //
const GridLoading = ({ count = 6 }) => (
  <Box sx={{ p: 2 }}>
    <Grid container spacing={2}>
      {Array.from(new Array(count)).map((_, index) => (
        <Grid item key={index} xs={12} sm={6} md={4}>
          <Box
            sx={{
              borderRadius: 2,
              overflow: "hidden",
              border: 1,
              borderColor: 'rgba(255, 228, 186, 0.8)',
            }}
          >
            <Skeleton variant="rectangular" width="100%" height={200} />
            <Box sx={{ p: 1 }}>
              <Skeleton width="80%" />
              <Skeleton width="60%" />
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  </Box>
);


// const GridLoading = ({ count = 6 }) => (
//   <Box sx={{ p: 2 }}>
//     <Grid container spacing={2}>
//       {Array.from(new Array(count)).map((_, index) => (
//         <Grid item key={index} xs={12} sm={6} md={4}>
//           <Box
//             sx={{
//               borderRadius: 2,
//               overflow: "hidden",
//               border: 1,
//               borderColor: "rgba(255, 228, 186, 0.8)",
//               display: "flex",
//               flexDirection: "column",
//             }}
//           >
//             {/* Thumbnail Skeleton */}
//             <Skeleton variant="rectangular" width="100%" height={200} />
//             <Box sx={{ p: 2 }}>
//                {/* Line Skeleton */}
//                <Skeleton width="70%" height={20}  sx={{ mb: 2 }}/> 
//               {/* Icons for Actions (e.g., like, share, comment) */}
//               <Stack direction="row" justifyContent="space-between">
//                 <Skeleton variant="circular" width={30} height={30} />
//                 <Skeleton variant="circular" width={30} height={30} />
//                 <Skeleton variant="circular" width={30} height={30} />
//                 {/* Play Button + Line Skeleton */}
//                 <Skeleton variant="circular" width={40} height={40} spacing={1}/>
//               </Stack>
//             </Box>
//           </Box>
//         </Grid>
//       ))}
//     </Grid>
//   </Box>
// );
// ==============================|| Card Loading Skeleton ||============================== //
const CardLoader = () => (
  <>
  <Box sx={{ py: 4 }}>     
  <Grid container spacing={4}>
    {Array.from(new Array(2)).map((_, index) => (
      <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
        <Card sx={{ display: 'flex', alignItems: 'center', padding: 2 }}>
          <Skeleton
            variant="circular"
            width={64}
            height={64}
            sx={{ flexShrink: 0 }}
          />
          <Box sx={{ marginLeft: "16px", flex: 1 }}>
            <Skeleton width="60%" height={32} />
            <Skeleton width="40%" height={24} sx={{ marginTop: "8px" }} />
          </Box>
        </Card>
      </Grid>
    ))}
  </Grid>
  </Box>
  </>
);





const ProfileSkeleton = () => (
  <Box sx={{ pb: "10px" }}>
    <Typography variant="h5" sx={{ mb: 2 }}>
      <Skeleton width="200px" />
    </Typography>
    <Paper
      sx={{
        boxShadow: "none",
        borderRadius: "12px",
        border: "1px solid",
        borderColor: "divider",
        p: 3,
      }}
    >
      <Grid container spacing={3}>
        {/* Avatar Section Skeleton */}
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Skeleton variant="circular" width={150} height={150} />
          </Box>
        </Grid>

        {/* User Info Section Skeleton */}
        <Grid item xs={12} md={8}>
          <Stack direction="row" spacing={4} sx={{ mb: 3 }}>
            {Array.from({ length: 4 }).map((_, index) => (
              <Stack key={index} direction="row" spacing={1} alignItems="center">
                <Skeleton variant="circular" width={30} height={30} />
                <Skeleton width="80px" height="20px" />
              </Stack>
            ))}
          </Stack>
          <Skeleton width="60%" height="24px" sx={{ mb: 1 }} />
          <Skeleton width="40%" height="20px" sx={{ mb: 1 }} />
          <Skeleton width="50%" height="20px" sx={{ mb: 1 }} />
          <Skeleton width="30%" height="20px" sx={{ mb: 1 }} />
          <Skeleton width="70%" height="20px" sx={{ mb: 1 }} />
          <Skeleton width="40%" height="20px" sx={{ mb: 1 }} />
          <Skeleton width="60%" height="20px" sx={{ mb: 1 }} />
        </Grid>
      </Grid>
    </Paper>
  </Box>
);



const UserCardLoader = ({ count = 6 }) =>  (
  <Grid container spacing={3} mt={2}>
  {Array.from(new Array(count)).map((_, index) => (
    <Grid item xs={12} md={6} lg={4} key={index}>
      <Paper
        sx={{
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "rgba(0, 0, 0, 0.12)", // Light border for differentiation
          borderRadius: "12px",
          p: "20px",
          bgcolor: "#f9f9f9",
        }}
      >
        <Box sx={{ textAlign: "center" }}>
          <Skeleton
            variant="circular"
            width={100}
            height={100}
            sx={{ mx: "auto" }}
          />
          <Box sx={{ mt: 2 }}>
            <Skeleton variant="text" width="60%" height={25} sx={{ mx: "auto" }} />
            <Skeleton variant="text" width="80%" height={20} sx={{ mx: "auto", mt: 1 }} />
            <Skeleton variant="text" width="70%" height={20} sx={{ mx: "auto", mt: 1 }} />
            <Skeleton variant="text" width="40%" height={20} sx={{ mx: "auto", mt: 1 }} />
            <Skeleton variant="text" width="60%" height={25} sx={{ mx: "auto" }} />
            <Skeleton variant="text" width="80%" height={20} sx={{ mx: "auto", mt: 1 }} />
            <Skeleton variant="text" width="70%" height={20} sx={{ mx: "auto", mt: 1 }} />
            <Skeleton
              variant="rectangular"
              width="90%"
              height={80}
              sx={{ mx: "auto", mt: 2 }}
            />
          </Box>
        </Box>
      </Paper>
    </Grid>
  ))}
</Grid>
  );



  const UnassigenedUplaodVideoLoader = () => {
    return (
      <Grid container spacing={2}>
        {Array.from(new Array(6)).map((_, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Box
              sx={{
                height: "200px",
                borderRadius: "10px",
                backgroundColor: "#e0e0e0",
                animation: "pulse 1.5s infinite",
              }}
            ></Box>
          </Grid>
        ))}
      </Grid>
    );
  };

export { Loader, GridLoading, CardLoader,ProfileSkeleton,UserCardLoader ,UnassigenedUplaodVideoLoader};