import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Box
} from "@mui/material";
// import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
const LogoutModal = ({ open, handleClose, handleLogout }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="logout-dialog-title"
      aria-describedby="logout-dialog-description"  
      maxWidth="xs" // Ensures a compact modal
      fullWidth
      >
     <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", mt: 2 }}>
        <ErrorOutlineIcon sx={{ fontSize: 50, color: "warning.main" }} />
      </Box>
      <DialogTitle id="logout-dialog-title"  sx={{ textAlign: "center", fontSize: "1.2rem", fontWeight: "bold" }}>Confirm Logout</DialogTitle>
      <DialogContent>
        <DialogContentText  sx={{ textAlign: "center" }} id="logout-dialog-description">
          Are you sure you want to log out?
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{pb:2}}>
        <Button sx={{borderRadius:"18px"}} onClick={handleClose} variant="outlined" color="primary">
          Cancel
        </Button>
        <Button
          sx={{borderRadius:"18px"}} 
          onClick={handleLogout}
          variant="contained"
          color="error"
          autoFocus
        >
          Logout
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LogoutModal;
