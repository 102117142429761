

import { useState, useEffect, useRef } from "react";
import { getResponse } from "../../../utils/axios_api_utils";

const useUnpublishVideos = () => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const isFetched = useRef(false); // Prevent multiple API calls.

 useEffect(() => {
    // Prevent multiple API calls.
    if (isFetched.current) return;
    isFetched.current = true;

 const fetchUnpublishVideos = async () => {
      setLoading(true);
      try {
        const response = await getResponse("base", "list/unpublish/videos");

        if (response.status === 200) {
          setVideos(response.data || []); // Update the state with API data.
        } else {
          throw new Error(`API responded with status ${response.status}`);
        }
      } catch (error) {
        // Ignore errors caused by request cancellation.
        console.error("Failed to fetch unpublished videos:", error.message);
      } finally {
        setLoading(false); // End loading state.
      }
    };
   fetchUnpublishVideos();
  }, []);

  return { videos, loading };
};

export default useUnpublishVideos;