import { useState, useRef } from "react";
import { toast } from "react-toastify";
import { postResponse } from "../../../utils/axios_api_utils";



export const useUploadVideos = () => {
  const [videos, setVideos] = useState([]);
  const [uploading, setUploading] = useState(false);
  const videoRefs = useRef([]);

  // Handle file selection
  const handleFileChange = (e) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);
      const videoFiles = files.filter((file) => file.type.startsWith("video/"));

      if (videoFiles.length !== files.length) {
        toast.error("Only video files are allowed.");
        return;
      }

      if (videoFiles.length + videos.length > 10) {
        toast.error(`You can upload a maximum of ${10} videos.`);
        return;
      }

      setVideos((prevVideos) => [...prevVideos, ...videoFiles]);
    }
  };

  // Remove video from the list
  const handleRemoveVideo = (index, event) => {
    event.stopPropagation(); // Prevent click propagation to parent
    setVideos((prev) => prev.filter((_, i) => i !== index));
  };

  // Play only one video at a time
  const handlePlayVideo = (index) => {
    videoRefs.current.forEach((video, i) => {
      if (video && i !== index) video.pause();
    });
  };

  // Upload videos to the server
  const handleUpload = async () => {
    if (videos.length === 0) {
      toast.error("Please select at least one video to upload.");
      return;
    }

    setUploading(true);
    try {
      for (const video of videos) {
        const formData = new FormData();
        formData.append("file", video);
        const response = await postResponse("videoUnassigned", "upload/video/unassigned", formData);
        if (response?.status === 200) {
          toast.success("Video uploaded successfully!");
        } else {
          throw new Error(`Upload failed with status: ${response?.status}`);
        }
      }

      setVideos([]); // Clear videos after successful upload
    } catch (error) {
      console.error("Error uploading videos:", error);
      toast.error("Failed to upload videos. Please try again.");
    } finally {
      setUploading(false);
    }
  };

  return {
    videos,
    uploading,
    videoRefs,
    handleFileChange,
    handleRemoveVideo,
    handleUpload,
    handlePlayVideo,
  };
};
