import {
  Box,
  Button,
  Typography,
  Grid,
  CardMedia,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Paper,
  CircularProgress,
  IconButton,
} from "@mui/material";
import Breadcrumb from "../../components/common/Breadcrumb/Breadcrumb";
import { UnassigenedUplaodVideoLoader } from "../../components/common/loader/Loader";
import { useUploadUnpublishVideos } from ".//hooks/useUploadUnpublishVideos";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const UploadUnpublishVideos = () => {
  const {
    users,
    selectedUser,
    videos,
    selectedVideos,
    loading,
    uploading,
    videoRefs,
    handleUserChange,
    handleVideoSelect,
    handleUpload,
    handleVideoPlay,
    VIDEO_BASE_URL,
  } = useUploadUnpublishVideos();

  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <Breadcrumb />
      <Paper sx={{ p: "20px", borderRadius: "12px", border: "1px solid #ddd" }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Upload Unpublished Videos
        </Typography>

                {/* User Selection Dropdown */}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box sx={{ mt: 1 }}>
             {/* 🔹 User Selection Dropdown */}
        <FormControl fullWidth size="small" sx={{ mb: 4 }}>          
          <InputLabel id="user-select-label">Select User</InputLabel>
          <Select
            labelId="user-select-label"
            id="user-select-label"
            value={selectedUser}
            onChange={handleUserChange}
            label="Select User"
            renderValue={(selected) => selected}
            disabled={loading}
            MenuProps={MenuProps}
          >
           <MenuItem disabled value=""> <em>Select a user</em></MenuItem>
            {loading ? (
              <MenuItem disabled> <em>Fetching users...</em> </MenuItem>
            ) : (
              users.map((user) => (
                <MenuItem key={user.id} value={user.userName}>
                  <Checkbox checked={selectedUser === user.userName} />
                  <ListItemText primary={user.userName} />
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>
            </Box>
          </Grid>
        </Grid>

        {/* Videos List */}
        <Typography variant="h6" sx={{ mb: 2 }}>
          Videos List
        </Typography>
        {loading ? (
          <UnassigenedUplaodVideoLoader />
        ) : videos.length > 0 ? (
          <Grid container spacing={2}>
            {videos.map((video, index) => (
              <Grid item xs={12} sm={6} md={4} key={video.Key}>
                <Box sx={{ position: "relative" }}>
                  <CardMedia
                    component="video"
                    src={`${VIDEO_BASE_URL}/${video.Key}`}
                    controls
                    onPlay={() => handleVideoPlay(index)}
                    ref={(el) => (videoRefs.current[index] = el)}
                    sx={{ height: 200, borderRadius: "10px", border: "1px solid #ddd", width: "100%" }}
                  />
                  <IconButton onClick={() => handleVideoSelect(video.Key)} sx={{ position: "absolute", top: 0, right: 0 }}>
                    <Checkbox checked={selectedVideos.includes(video.Key)} />
                  </IconButton>
                </Box>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography color="gray" textAlign="center">
            No videos available.
          </Typography>
        )}

        {/* Upload Button */}
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Button   sx={{ borderRadius: "20px" }} variant="contained" onClick={handleUpload} disabled={uploading || selectedVideos.length === 0}>
            {uploading ? <CircularProgress size={20} sx={{ mr: 1 }} /> : "Upload Videos"}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default UploadUnpublishVideos;





// import React, { useState, useEffect, useRef } from "react";
// import axios from "axios";
// import {
//   Box,
//   Button,
//   Typography,
//   Grid,
//   CardMedia,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Checkbox,
//   ListItemText,
//   Paper,
//   CircularProgress,
//   IconButton,
// } from "@mui/material";
// import { getResponse } from "../../utils/axios_api_utils";
// import { toast } from "react-toastify";
// import Breadcrumb from "../../components/common/Breadcrumb/Breadcrumb";
// import { UnassigenedUplaodVideoLoader } from "../../components/common/loader/Loader";

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };
// // API and URL constants
// const VIDEO_API_URL  = "https://s2lieet7xb.execute-api.ap-south-1.amazonaws.com/dev/list/video/unassigned";
// const UPLOAD_API_URL = "https://hmllmmo5bj.execute-api.ap-south-1.amazonaws.com/dev/uploadUnPublish";
// const VIDEO_BASE_URL = "https://bloopers-unassigned-videos.s3.ap-south-1.amazonaws.com/";

// const UploadUnpublishVideos = () => {
//  // State for users
//  const [users, setUsers] = useState([]);
//  const [selectedUser, setSelectedUser] = useState("");

//  // State for videos
//  const [videos, setVideos] = useState([]);
//  const [selectedVideos, setSelectedVideos] = useState([]);

//  // State for loading & uploading
//  const [loading, setLoading] = useState(false);
//  const [uploading, setUploading] = useState(false);

//  // Ref for video elements
//  const videoRefs = useRef([]);
 

//   // 🔹 Fetch users from API
//   const fetchUsers = async () => {
//     setLoading(true);
//     try {
//       const response = await getResponse("base", "users");
//       if (response?.data) {
//         setUsers(response.data);
//       } else {
//         toast.error("Failed to fetch users.");
//       }
//     } catch (error) {
//       toast.error("Error fetching users.");
//     } finally {
//       setLoading(false);
//     }
//   };


//   // 🔹 Fetch videos from API
//   const fetchVideos = async () => {
//     setLoading(true);
//     try {
//       const response = await axios.get(VIDEO_API_URL, {
//         params: { pageSize: 10 },
//         headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
//       });

//       setVideos(response.data.list || []);
//     } catch (error) {
//       toast.error("Failed to load videos.");
//     } finally {
//       setLoading(false);
//     }
//   };

//  // 🔹 Handle user selection
//  const handleUserChange = (event) => {
//   setSelectedUser(event.target.value);
//   setSelectedVideos([]); // Reset selected videos when changing users
// };

// // 🔹 Handle video selection
// const handleVideoSelect = (videoKey) => {
//   setSelectedVideos((prev) =>
//     prev.includes(videoKey)
//       ? prev.filter((key) => key !== videoKey)
//       : [...prev, videoKey]
//   );
// };

// // 🔹 Handle video play (pause other videos)
// const handleVideoPlay = (index) => {
//   videoRefs.current.forEach((video, i) => {
//     if (video && i !== index) video.pause();
//   });
// };

// // 🔹 Handle video upload
// const handleUpload = async () => {
//   if (!selectedUser) {
//     toast.error("Please select a user first.");
//     return;
//   }
//   if (selectedVideos.length === 0) {
//     toast.error("Please select at least one video.");
//     return;
//   }

//   setUploading(true);
//   try {
//     const selectedUserData = users.find((user) => user.userName === selectedUser);
//     if (!selectedUserData) {
//       toast.error("User not found.");
//       return;
//     }

//     const payload = {
//       userVideos: selectedVideos.map((videoKey) => ({
//         Key: videoKey,
//         userId: selectedUserData.id,
//         videoId: crypto.randomUUID(),
//         userName: selectedUserData.userName,
//         firstName: selectedUserData.firstName,
//         lastName: selectedUserData.lastName,
//       })),
//     };

//     const response = await axios.post(UPLOAD_API_URL, payload, {
//       headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
//     });

//     if (response.status === 200) {
//       toast.success("Videos uploaded successfully!");
//       fetchVideos();
//       setSelectedVideos([]); // Reset after upload
//     } else {
//       toast.error("Upload failed.");
//     }
//   } catch (error) {
//     toast.error("Upload error. Try again.");
//   } finally {
//     setUploading(false);
//   }
// };

//   // 🔹 Fetch users when component mounts
//   useEffect(() => {
//     fetchUsers();
//   }, []);
//    // 🔹 Fetch videos when a user is selected
//    useEffect(() => {
//     if (selectedUser) {
//       fetchVideos();
//     }
//   }, [selectedUser]);



//   return (
//     <Box sx={{ pt: "80px", pb: "20px" }}>
//     <Breadcrumb />
//     <Paper sx={{ p: "20px", borderRadius: "12px", border: "1px solid #ddd" }}>
//       <Typography variant="h6" sx={{ mb: 2 }}>
//         Upload Unpublished Videos
//       </Typography>

//         {/* User Selection Dropdown */}
//         <Grid container spacing={2}>
//           <Grid item xs={12} sm={6}>
//             <Box sx={{ mt: 1 }}>
//               {/* 🔹 User Selection Dropdown */}
//         <FormControl fullWidth size="small" sx={{ mb: 4 }}>          
//           <InputLabel id="user-select-label">Select User</InputLabel>
//           <Select
//             labelId="user-select-label"
//             id="user-select-label"
//             value={selectedUser}
//             onChange={handleUserChange}
//             label="Select User"
//             renderValue={(selected) => selected}
//             disabled={loading}
//             MenuProps={MenuProps}
//           >
          
//             {loading ? (
//               <MenuItem disabled>Loading users...</MenuItem>
//             ) : (
//               users.map((user) => (
//                 <MenuItem key={user.id} value={user.userName}>
//                   <Checkbox checked={selectedUser === user.userName} />
//                   <ListItemText primary={user.userName} />
//                 </MenuItem>
//               ))
//             )}
//           </Select>
//         </FormControl>


//             </Box>
//           </Grid>
//         </Grid>

//               {/* 🔹 Videos List */}
//               <Typography variant="h6" sx={{ mb: 2 }}>
//           Videos List
//         </Typography>
//         {loading ? (
//           <UnassigenedUplaodVideoLoader />
//         ) : videos.length > 0 ? (
//           <Grid container spacing={2}>
//             {videos.map((video, index) => (
//               <Grid item xs={12} sm={6} md={4} key={video.Key}>
//                 <Box sx={{ position: "relative", borderRadius: 3, "&:hover": { boxShadow: 5 } }}>
//                   <CardMedia
//                     component="video"
//                     src={`${VIDEO_BASE_URL}${video.Key}`}
//                     controls
//                     onPlay={() => handleVideoPlay(index)}
//                     ref={(el) => (videoRefs.current[index] = el)}
//                     sx={{ height: 200, borderRadius: "10px", border: "1px solid #ddd", width: "100%" }}
//                   />
//                   <IconButton
//                     onClick={() => handleVideoSelect(video.Key)}
//                     sx={{ position: "absolute", top: 0, right: 0 }}
//                   >
//                     <Checkbox checked={selectedVideos.includes(video.Key)} />
//                   </IconButton>
//                 </Box>
//               </Grid>
//             ))}
//           </Grid>
//         ) : (
//           <Typography color="gray" textAlign="center">
//             No videos available.
//           </Typography>
//         )}

//            {/* 🔹 Upload Button */}
//            <Box display="flex" justifyContent="flex-end" mt={2}>
//           <Button
//            sx={{ borderRadius: "20px" }}
//             variant="contained"
//             onClick={handleUpload}
//             disabled={uploading || selectedVideos.length === 0}
//           >
//             {uploading ? <CircularProgress size={20} sx={{ mr: 1 }} /> : "Upload Videos"}
//           </Button>
//         </Box>
//       </Paper>
//     </Box>
//   );
// };

// export default UploadUnpublishVideos;





