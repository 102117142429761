import { Paper, Typography, Box } from "@mui/material";
import React from "react";

const Footer = () => {
  return (
    <Paper
      sx={{
        boxShadow: "none",
        borderRadius: "12px",
        border: "1px solid",
        borderColor: "divider",
        py: 2,
        px: 3,
        mt: 4,
        textAlign: "center",
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap" // Ensures responsiveness on smaller screens
      >
        <Typography variant="body2" color="text.secondary">
          Created by{" "}
        </Typography>

        <Typography
          component="a"
          href="https://lalitmittaltechnologies.com/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Visit LMT website"
          sx={{
            color: "#027edd",
            fontWeight: "bold",
            textDecoration: "none",
            mx: 1,
          }}
        >
         | LMT |
        </Typography>

        <Typography variant="body2" color="text.secondary">
          Bloopers Admin Dashboard | All Rights Reserved &copy; {new Date().getFullYear()}
        </Typography>
      </Box>
    </Paper>
  );
};

export default Footer;
