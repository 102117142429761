// import { 
//   FiHome, 
//   FiUsers, 
//   FiGlobe, 
//   FiTag, 
//   FiVideo, 
//   FiVideoOff, 
//   FiFolder, 
//   FiAlertCircle 
// } from "react-icons/fi";

// import { FaVideo, FaRegFileVideo } from "react-icons/fa";
// import { MdOutlineCategory } from "react-icons/md";
// import { BiCategoryAlt } from "react-icons/bi";
// import { IoVideocamOffOutline } from "react-icons/io5";

// export const links = [
//   {
//     name: "Dashboard",
//     icon: <FiHome />, // Home icon for Dashboard
//     url: "/dashboard",
//   },
//   {
//     name: "Users",
//     icon: <FiUsers />, // Users icon for User Management
//     url: "/users",
//   },
//   {
//     name: "Languages",
//     icon: <FiGlobe />, // Globe icon for Languages
//     url: "/languages",
//   },
//   {
//     name: "HashTags",
//     icon: <FiTag />, // Tag icon for Hashtags
//     url: "/hashTags",
//   },
//   {
//     name: "Report Reason",
//     icon: <FiAlertCircle />, // Alert icon for Reports
//     url: "/report-reason",
//   },
//   {
//     name: "Unassigned Videos",
//     icon: <IoVideocamOffOutline />, // Camera off icon for Unassigned Videos
//     url: "/video-unassigned",
//   },
  
//   // {
//   //   name: "Category",
//   //   icon: <FiFolder />,
//   //   url: "/category",
//   // },
//   {
//     name: "Videos Categories",
//     icon: <BiCategoryAlt />, // Category icon for Video Categories
//     url: "/video-category",
//   },
//   {
//     name: "Unpublished Videos",
//     icon: <FiVideoOff />, // Video off icon for Unpublished Videos
//     url: "/video-unpublish",
//   },
//   {
//     name: "Published Videos",
//     icon: <FaRegFileVideo />, // File video icon for Published Videos
//     url: "/video-published",
//   },
// ];


import { 
  FiHome, 
  FiUsers, 
  FiGlobe, 
  FiTag, 
  FiVideo, 
  FiVideoOff, 
  FiAlertCircle 
} from "react-icons/fi";

import { FaRegFileVideo } from "react-icons/fa";
import { BiCategoryAlt } from "react-icons/bi";
import { IoVideocamOffOutline } from "react-icons/io5";

export const links = [
  {
    name: "Dashboard",
    icon: <FiHome />, 
    url: "/dashboard",
  },
  {
    name: "User Management",
    icon: <FiUsers />,
    subLinks: [
      { name: "Users", url: "/users" },
    ],
  },
  {
    name: "Content Management",
    icon: <FiGlobe />,
    subLinks: [
      { name: "Languages", url: "/languages" },
      { name: "Hashtags", url: "/hashTags" },
      { name: "Report Reasons", url: "/report-reason" },
      { name: "Video Categories", url: "/video-category" },
    ],
  },
  {
    name: "Video Management",
    icon: <FiVideo />,
    subLinks: [
      { name: "Unassigned Videos", url: "/video-unassigned" },
      { name: "Unpublished Videos", url: "/video-unpublish" },
      { name: "Published Videos", url: "/video-published" },
    ],
  },
];
