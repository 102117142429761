import {
    Box,
    Button,
    Paper,
    TextField,
    Typography,
    CircularProgress,
  } from "@mui/material";
  import React, { useState } from "react";
  import axios from "axios";
  import { toast } from "react-toastify";
  import "react-toastify/dist/ReactToastify.css";
  import Breadcrumb from "../../components/common/Breadcrumb/Breadcrumb";
  
  const CreateCategory = () => {
    const [categoryName, setCategoryName] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
  
    const validateInput = () => {
      if (!categoryName.trim()) {
        setError("Category Name is required.");
        return false;
      }
      if (categoryName.length < 3) {
        setError("Category Name must be at least 3 characters long.");
        return false;
      }
      setError("");
      return true;
    };
  
    const handleSubmit = async () => {
      if (!validateInput()) {
        return;
      }
  
      setLoading(true);
  
      try {
        const token = localStorage.getItem("token");
        const response = await axios.post(
          "https://m4h5sy01i9.execute-api.ap-south-1.amazonaws.com/dev/create/video/category",
          { categoryName },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
  
        if (response.status === 200) {
          toast.success("Category added successfully!");
          setCategoryName(""); // Clear the input field after success
        } else {
          throw new Error("Failed to add category.");
        }
      } catch (error) {
        console.error("Error adding category:", error);
        toast.error(error.response?.data?.message || "Failed to add category. Please try again.");
      } finally {
        setLoading(false);
      }
    };
  
    const handleCancel = () => {
      setCategoryName("");
      setError("");
    };
  
    const handleChange = (e) => {
      const { value } = e.target;
      setCategoryName(value);
      // Clear the error when the user starts typing
      if (value.trim()) { setError("");}
    };
  
    return (
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Breadcrumb />
        <Paper
          sx={{
            boxShadow: "none !important",
            borderRadius: "12px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "divider",
            p: "20px",
            margin: "0 auto",
            cursor: "pointer",
            overflow: "hidden",
          }}
        >
          <Typography variant="h6" sx={{ marginBottom: "14px" }}>
            Add Category
          </Typography>
          <Box sx={{ my: 2 }}>
            <TextField
              label="Category Name"
              variant="outlined"
              size="small"
              fullWidth
              required
              value={categoryName}
              onChange={handleChange} // Use the new handleChange function
              error={!!error}
              helperText={error}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: "30px",
            }}
          >
            <Button
              variant="contained"
              sx={{ borderRadius: "20px", marginRight: "10px" }}
              onClick={handleCancel}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ borderRadius: "20px" }}
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? <CircularProgress size={20} color="inherit" /> : "Submit"}
            </Button>
          </Box>
        </Paper>
      </Box>
    );
  };
  
  export default CreateCategory;