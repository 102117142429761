import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const INITIAL_FORM_DATA = {
  firstName: "",
  lastName: "",
  email_phone: "",
  device_id: "",
  password: "",
};

export const useCreateUser = () => {
  const [formData, setFormData] = useState(INITIAL_FORM_DATA);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  // Validate input fields
  const validateInput = () => {
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\+?[1-9]\d{1,14}$/; // E.164 format

    if (!formData.firstName.trim()) newErrors.firstName = "First name is required.";
    if (!formData.lastName.trim()) newErrors.lastName = "Last name is required.";

    if (!formData.email_phone.trim()) {
      newErrors.email_phone = "Email or phone is required.";
    } else if (!emailRegex.test(formData.email_phone.trim()) && !phoneRegex.test(formData.email_phone.trim())) {
      newErrors.email_phone = "Please enter a valid email or phone number.";
    }

    if (!formData.device_id.trim()) newErrors.device_id = "Device ID is required.";
    if (!formData.password.trim() || formData.password.length < 6) {
      newErrors.password = "Password must be at least 6 characters.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async () => {
    if (!validateInput()) return;

    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        "http://localhost:8000/user/createUser",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        toast.success("User created successfully!");
        resetForm();
      } else {
        throw new Error("Failed to create user.");
      }
    } catch (error) {
      console.error("Error creating user:", error);
      toast.error(error.response?.data?.message || "Failed to create user. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  // Reset form
  const resetForm = () => {
    setFormData(INITIAL_FORM_DATA);
    setErrors({});
  };

  return {
    formData,
    loading,
    errors,
    handleChange,
    handleSubmit,
    resetForm,
  };
};
