import styled from "@emotion/styled";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Typography,
  Grid,
  CircularProgress,
  CardMedia,
} from "@mui/material";
import { MdClose } from "react-icons/md";
import { FaVideo } from "react-icons/fa";
import Breadcrumb from "../../components/common/Breadcrumb/Breadcrumb";
import { useUploadVideos } from "./hooks/useUploadVideos";

const UploadBox = styled(Box)({
  padding: "40px",
  marginTop: 30,
  borderRadius: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  borderStyle: "dashed",
  borderWidth: "2px",
  borderColor: "divider",
});

const UploadVideo = () => {
  const {
    videos,
    uploading,
    videoRefs,
    handleFileChange,
    handleRemoveVideo,
    handleUpload,
    handlePlayVideo,
  } = useUploadVideos();

  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <Breadcrumb />
      <Paper
        sx={{
          boxShadow: "none !important",
          borderRadius: "12px",
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "divider",
          p: "20px",
          margin: "0 auto",
          overflow: "hidden",
        }}
      >
        <Typography variant="h6" sx={{ marginBottom: "1px" }}>
          Upload Videos
        </Typography>
        <input type="file" id="video-upload-input" hidden multiple accept="video/*" onChange={handleFileChange} />
        <UploadBox>
          <Box sx={{ textAlign: "center" }}>
            <FaVideo style={{ fontSize: "50px", color: "#027edd" }} />
            <Typography>
              Select your videos here or{" "}
              <label htmlFor="video-upload-input" style={{ color: "#027edd", cursor: "pointer", fontWeight: "bold" }}>
                browse
              </label>
            </Typography>
            <Typography sx={{ fontSize: "12px" }}>Only video files are allowed (Max: 10 videos)</Typography>
          </Box>
        </UploadBox>

        {/* Video Preview List */}
        <Grid container spacing={2} sx={{ marginTop: 2 }}>
          {videos.map((video, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Box sx={{ position: "relative" }}>
                <CardMedia
                  component="video"
                  controls
                  style={{ height: "200px", borderRadius: "10px", border: "1px solid #ddd" }}
                  src={URL.createObjectURL(video)}
                  onPlay={() => handlePlayVideo(index)}
                  ref={(el) => (videoRefs.current[index] = el)}
                />
                <IconButton
                  color="error"
                  onClick={(event) => handleRemoveVideo(index, event)}
                  sx={{ position: "absolute", top: 8, right: 8 }}
                >
                  <MdClose />
                </IconButton>
              </Box>
            </Grid>
          ))}
        </Grid>
        {/* Upload Button */}
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: "30px" }}>
          <Button variant="contained" sx={{ borderRadius: "20px", position: "relative" }} onClick={handleUpload} disabled={uploading}>
            {uploading ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <CircularProgress size={20} sx={{ color: "black", mr: 1 }} />
                Uploading...
              </Box>
            ) : (
              "Upload Video"
            )}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default UploadVideo;





// import styled from "@emotion/styled";
// import {
//   Box,
//   Button,
//   IconButton,
//   Paper,
//   Typography,
//   Grid,
//   CircularProgress,
//   CardMedia
// } from "@mui/material";
// import React, { useState, useRef } from "react";
// import { MdClose } from "react-icons/md";
// import axios from "axios";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import ReactPlayer from "react-player";
// import { FaVideo } from "react-icons/fa";
// import Breadcrumb from "../../components/common/Breadcrumb/Breadcrumb";
// const UPLOAD_API_URL = "https://s2lieet7xb.execute-api.ap-south-1.amazonaws.com/dev/upload/video/unassigned";

// const UploadVideo = () => {
//   const [videos, setVideos] = useState([]);
//   const [uploading, setUploading] = useState(false);
//   const token = localStorage.getItem("token");
//   const videoRefs = useRef([]);

//   const UploadBox = styled(Box)({
//     padding: "40px",
//     marginTop: 30,
//     borderRadius: "10px",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     flexDirection: "column",
//     borderStyle: "dashed",
//     borderWidth: "2px",
//     borderColor: "divider",
//   });

//   const handleFileChange = (e) => {
//     if (e.target.files) {
//       const files = Array.from(e.target.files);
//       const videoFiles = files.filter((file) => file.type.startsWith("video/"));

//       if (videoFiles.length !== files.length) {
//         toast.error("Only video files are allowed.");
//         return;
//       }

//       if (videoFiles.length + videos.length > 10) {
//         toast.error("You can upload a maximum of 10 videos.");
//         return;
//       }

//       setVideos((prevVideos) => [...prevVideos, ...videoFiles]);
//     }
//   };

//   const handleRemoveVideo = (index, event) => {
//     event.stopPropagation(); // Prevent click propagation to parent
//     const updatedVideos = videos.filter((_, i) => i !== index);
//     setVideos(updatedVideos);
//   };

  
//   const handleUpload = async () => {
//     if (videos.length === 0) {
//       toast.error("Please select at least one video to upload.");
//       return;
//     }

//     setUploading(true);
//     try {
//       for (const video of videos) {
//         const formData = new FormData();
//         formData.append("file", video);

//         await axios.post(UPLOAD_API_URL, formData, {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         });
//       }

//       toast.success("Videos uploaded successfully!");
//       setVideos([]); // Clear videos after upload
//     } catch (error) {
//       console.error("Error uploading videos:", error);
//       toast.error("Failed to upload videos. Please try again.");
//     } finally {
//       setUploading(false);
//     }
//   };


//   const handlePlayVideo = (index) => {
//     videoRefs.current.forEach((video, i) => {
//       if (video && i !== index && !video.paused) {
//         video.pause();
//       }
//     });
//   };
  // const handlePlayVideo = (index) => {
  //   videoRefs.current.forEach((player, i) => {
  //     if (player && i !== index && player.getInternalPlayer) {
  //       const internalPlayer = player.getInternalPlayer();
  //       if (internalPlayer.pause) internalPlayer.pause();
  //     }
  //   });
  // };

//   return (
//     <Box sx={{ pt: "80px", pb: "20px" }}>
//       <Breadcrumb/>  
//       <Paper
//         sx={{
//           boxShadow: "none !important",
//           borderRadius: "12px",
//           borderStyle: "solid",
//           borderWidth: "1px",
//           borderColor: "divider",
//           p: "20px",
//           margin: "0 auto",
//           overflow: "hidden",
//         }}
//       >
//          <Typography variant="h6" sx={{ marginBottom: "1px" }}>
//         Upload Videos
//       </Typography>
//         <input
//           type="file"
//           id="video-upload-input"
//           hidden
//           multiple
//           accept="video/*"
//           onChange={handleFileChange}
//         />
//         <UploadBox>
//           <Box sx={{ textAlign: "center" }}>
//             <FaVideo style={{ fontSize: "50px", color: "#027edd" }} />
//             <Typography>
//               Select your videos here or {" "}
//               <label
//                 htmlFor="video-upload-input"
//                 style={{ color: "#027edd", cursor: "pointer",fontWeight:"bold" }}
//               >
//                 browse
//               </label>
//             </Typography>
//             <Typography sx={{ fontSize: "12px" }}>
//               Only video files are allowed (Max: 10 videos)
//             </Typography>
//           </Box>
//         </UploadBox>

    
//         <Grid container spacing={2} sx={{ marginTop: 2 }}>
//           {videos.map((video, index) => (
//             <Grid item xs={12} sm={6} md={4} key={index}>
//               <Box
//                 sx={{
//                   position: "relative",
//                   // border: "1px solid #ddd",
//                   // borderRadius: 2,
//                   // "&:hover": { boxShadow: 5 },
//                 }}>
               

{/* <CardMedia
                        component="video"
                  
                        controls
                        style={{ height: "200px", borderRadius: "10px" , border: "1px solid #ddd"}}
                        src={URL.createObjectURL(video)}
                        onPlay={() => handlePlayVideo(index)}
                        ref={(el) => (videoRefs.current[index] = el)}
                      /> */}
             
//                 <IconButton
//                   color="error"
//                   onClick={(event) => handleRemoveVideo(index, event)}
//                   sx={{ position: "absolute", top: 8, right: 8 }}>
//                   <MdClose />
//                 </IconButton>
//               </Box>
//             </Grid>
//           ))}
//         </Grid>

//         <Box
//           sx={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             mt: "30px",
//           }}
//         >
//            <Button
//             variant="contained"
//             sx={{ borderRadius: "20px", position: "relative" }}
//             onClick={handleUpload}
//             disabled={uploading}
//           >
//             {uploading ? (
//               <Box sx={{ display: "flex", alignItems: "center" }}>
//                 <CircularProgress size={20} sx={{ color: "black", mr: 1 }} />
//                 Uploading...
//               </Box>
//             ) : (
//               "Upload Video"
//             )}
//           </Button>
         
//         </Box>
//       </Paper>
//     </Box>
//   );
// };

// export default UploadVideo;








