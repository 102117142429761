import { useState, useEffect, useRef, useCallback } from "react";
import { toast } from "react-toastify";
import { getResponse, postResponse } from "../../../utils/axios_api_utils";


const VIDEO_BASE_URL = process.env.REACT_APP_S3_VIDEO_BASE_URL || "";

export const useUploadUnpublishVideos = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");

  const [videos, setVideos] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);

  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);

  const videoRefs = useRef([]);
  const isFetchedUsers = useRef(false); 
//   const isFetchedVideos = useRef(false);


  // Fetch users from API
  const fetchUsers = useCallback(async () => {
    if (isFetchedUsers.current) return; // Prevent multiple API calls
    isFetchedUsers.current = true;

    setLoading(true);
    try {
      const response = await getResponse("base", "users");
      if (response?.status===200) {
        setUsers(response.data);
      } else {
        toast.error("Failed to fetch users.");
      }
    } catch (error) {
      toast.error("Error fetching users.");
    } finally {
      setLoading(false);
    }
  }, []);

  // Fetch videos from API
  const fetchVideos = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getResponse("videoUnassigned", "list/video/unassigned", {
        params: { pageSize: 10 },
      });

      if (response?.status===200) {
        setVideos(response.data.list);
      } else {
        toast.error("Failed to load videos.");
      }
    } catch (error) {
      toast.error("Error fetching videos.");
    } finally {
      setLoading(false);
    }
  }, []);

  // Handle user selection
  const handleUserChange = (event) => {
    setSelectedUser(event.target.value);
    setSelectedVideos([]); // Reset selected videos when changing users
  };

  // Handle video selection
  const handleVideoSelect = (videoKey) => {
    setSelectedVideos((prev) =>
      prev.includes(videoKey) ? prev.filter((key) => key !== videoKey) : [...prev, videoKey]
    );
  };

  // Pause other videos when playing one
  const handleVideoPlay = (index) => {
    videoRefs.current.forEach((video, i) => {
      if (video && i !== index) video.pause();
    });
  };

  // Upload selected videos
  const handleUpload = async () => {
    if (!selectedUser) {
      toast.error("Please select a user first.");
      return;
    }
    if (selectedVideos.length === 0) {
      toast.error("Please select at least one video.");
      return;
    }

    setUploading(true);
    try {
      const selectedUserData = users.find((user) => user.userName === selectedUser);
      if (!selectedUserData) {
        toast.error("User not found.");
        return;
      }

      const payload = {
        userVideos: selectedVideos.map((videoKey) => ({
          Key: videoKey,
          userId: selectedUserData.id,
          videoId: crypto.randomUUID(),
          userName: selectedUserData.userName,
          firstName: selectedUserData.firstName,
          lastName: selectedUserData.lastName,
        })),
      };

      const response = await postResponse("media", "uploadUnPublish", payload);
      if (response?.status === 200) {
        toast.success("Videos uploaded successfully!");
        setSelectedVideos([]); // Reset after upload
        // isFetchedVideos.current = false; //  Allow re-fetching of videos
        await fetchVideos(); // Refresh the video list
      } else {
        toast.error("Upload failed.");
      }
    } catch (error) {
      toast.error("Upload error. Try again.");
    } finally {
      setUploading(false);
    }
  };


  useEffect(() => {
    fetchUsers();
  }, []);


  useEffect(() => {
    if (selectedUser) {
      fetchVideos();
    }
  }, [selectedUser]);

  return {
    users,
    selectedUser,
    videos,
    selectedVideos,
    loading,
    uploading,
    videoRefs,
    handleUserChange,
    handleVideoSelect,
    handleUpload,
    handleVideoPlay,
    VIDEO_BASE_URL,
  };
};
