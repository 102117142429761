import axios from "axios";

// Base URLs for different services
const API_BASE_URLS = {
  base: process.env.REACT_APP_API_BASE_URL,
  general: process.env.REACT_APP_API_GENERAL_SERVICE,
  unpublish: process.env.REACT_APP_API_UNPUBLISH_VIDEOS,
  media: process.env.REACT_APP_API_USER_MEDIA_SERVICE,
  videoUnassigned: process.env.REACT_APP_API_UNASSIGNED_API_URL, // Updated key name for consistency
};

// Function to handle unauthorized access, clearing token, and redirecting to login
const handleUnauthorized = () => {
  console.warn("Unauthorized access. Redirecting to login...");
  localStorage.removeItem("token");
  window.location.href = "/";
};

// Centralized error handling function
const handleApiError = (error) => {
  if (error.response) {
    const { status, data } = error.response;
    const errorMessage = data?.message || error.message || "An error occurred.";

    switch (status) {
      case 401:
      case 403:
        console.error(`Unauthorized access (Status ${status}):`, errorMessage);
        handleUnauthorized();
        throw new Error("Unauthorized access. Please log in again.");
      case 404:
        console.error(`Resource not found (Status ${status}):`, errorMessage);
        throw new Error("Resource not found.");
      case 500:
        console.error(`Server error (Status ${status}):`, errorMessage);
        throw new Error("Server error. Please try again later.");
      default:
        console.error(`API Error (Status ${status}):`, errorMessage);
        throw new Error(errorMessage);
    }
  } else if (error.request) {
    console.error("No response received from API:", error.message);
    throw new Error("Network error. Please check your connection.");
  } else {
    console.error("Unexpected error:", error.message);
    throw new Error("An unexpected error occurred. Please try again.");
  }
};

// Function to generate Axios config with Authorization token and custom headers
const generateConfig = () => {
  const token = localStorage.getItem("token");
  if (!token) {
    console.warn("Authorization token is missing. Redirecting to login...");
    handleUnauthorized();
    throw new Error("Authorization token is missing.");
  }
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  };
};

// Helper to get the base URL for a specific service
const getBaseUrl = (serviceType) => {
  const baseUrl = API_BASE_URLS[serviceType];
  if (!baseUrl) {
    throw new Error(`Base URL not configured for service type: ${serviceType}`);
  }
  return baseUrl;
};

// 🔹 Generic Request Helper Function
const request = async (method, serviceType, endpoint, data = {}, options = {}) => {
  try {
    const baseUrl = getBaseUrl(serviceType);
    const config = { ...generateConfig(), ...options };
    const url = `${baseUrl}/${endpoint}`;

    let response;
    switch (method) {
      case "GET":
        response = await axios.get(url, config);
        break;
      case "POST":
        response = await axios.post(url, data, config);
        break;
      case "PUT":
        response = await axios.put(url, data, config);
        break;
      case "DELETE":
        response = await axios.delete(url, config);
        break;
      default:
        throw new Error(`Invalid HTTP method: ${method}`);
    }

    return response;
  } catch (error) {
    handleApiError(error);
  }
};

// 🔹 API Methods (Using `request` Helper)
export const getResponse = (serviceType, endpoint, options = {}) =>
  request("GET", serviceType, endpoint, {}, options);

export const getById = (serviceType, endpoint, id, options = {}) =>
  request("GET", serviceType, `${endpoint}/${id}`, {}, options);

export const postResponse = (serviceType, endpoint, data, options = {}) =>
  request("POST", serviceType, endpoint, data, options);

export const putResponse = (serviceType, endpoint, data, options = {}) =>
  request("PUT", serviceType, endpoint, data, options);

export const deleteResponse = (serviceType, endpoint, options = {}) =>
  request("DELETE", serviceType, endpoint, {}, options);
