import React from "react";
import styled from "@emotion/styled";
import { Box, Button, Typography } from "@mui/material";
import { FiPlus } from "react-icons/fi";
import { Link } from "react-router-dom";
import Table from "../../components/common/table/Table";
import useVideoCategories from "./hook/useVideoCategories";
import { categoryColumns } from "../../data/categories";

// Styled wrapper for consistent layout.
const ComponentWrapper = styled(Box)({
  marginTop: "10px",
  paddingBottom: "10px",
});


const VideoCategories = () => {
  const { categories, loading } = useVideoCategories();


  return (
    <Box sx={{ pt: "60px", pb: "20px" }}>

      <ComponentWrapper>
         <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "16px",
          marginTop: "20px",
        }}
      >
        <Typography variant="h6">Categorey</Typography>
        {/* <Link to="/video-category/create-category" style={{ textDecoration: "none" }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<FiPlus />}
            sx={{ borderRadius: "20px" }}
          >
            create Categorey
          </Button>
        </Link> */}
      </Box>
        <Table
          isLoading={loading}
          data={categories}
          fields={categoryColumns}
          // numberOfRows={7}
          enableTopToolBar={false}
          enableBottomToolBar={false}
          enablePagination={true}
          enableRowSelection={false}
          enableColumnFilters={false}
          enableEditing={false}
          enableColumnDragging={false}
        />
      </ComponentWrapper>
    </Box>
  );
};

export default VideoCategories;















