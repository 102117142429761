import * as React from "react";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import { useAuth } from "../../contexts/useAuth";
import Navbar from './navbar/Navbar';
import Sidebar from './sidebar/Sidebar';
import { Loader } from "../common/loader/Loader";
import Footer from './footer/Footer';
import NetworkStatus from "../common/network/NetworkStatus";
import { Outlet } from 'react-router-dom';

const sideBarWidth = 275;

function LayoutWrapper({ children }) {
  const { isAuthenticated, loading } = useAuth();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const location = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  // Check if the current page is the login page
  const isLoginPage = location.pathname === "/login";

  if (loading) {
   return <Loader />;
  }

  return (
    <Box sx={{ display: "flex"}}>
      {/* Conditionally render Navbar and Sidebar if user is authenticated and not on the login page */}
      {!isLoginPage && isAuthenticated && (
        <>
          <Navbar sideBarWidth={sideBarWidth}  handleDrawerToggle={handleDrawerToggle} />
          <Sidebar  sideBarWidth={sideBarWidth}  mobileOpen={mobileOpen}  handleDrawerToggle={handleDrawerToggle} />       
        </>
      )}
      {/* Main content area */}
      <Box component="main"
        sx={{ flexGrow: 1,  px: { xs: 1, md: 2 },
         //   width: { xs: "100%", md: `calc(100% - ${sideBarWidth}px)` },
          width: isAuthenticated ? { xs: "100%", md: `calc(100% - ${sideBarWidth}px)` } : "100%" }} >
       {/* Add NetworkStatus component */}
        <NetworkStatus />
        {/* {children} */}
        <Outlet /> 
         {/* Render child components (Routes, pages, etc.) */}
        {!isLoginPage && isAuthenticated && <Footer />}
      </Box>
    </Box>
  );
}
export default LayoutWrapper;







