import React, { useState } from "react";
import { Grid,Paper,Box,IconButton, Typography,Stack,Skeleton,Alert,CardMedia,} from "@mui/material";
import ReactPlayer from "react-player";
import { ThumbUp as ThumbUpIcon, ChatBubbleOutline as ChatBubbleOutlineIcon, Share as ShareIcon, PlayArrow as PlayArrowIcon, Pause as PauseIcon,} from "@mui/icons-material";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
const VideoGrid = ({ videos, loading }) => {
  const [playingVideoId, setPlayingVideoId] = useState(null); // Track which video is playing
  const [isPlaying, setIsPlaying] = useState(false); // Track play/pause state

  const handlePlayPause = (videoId) => {
    if (playingVideoId === videoId) {
      // Toggle play/pause for the same video
      setIsPlaying((prev) => !prev);
    } else {
      // Play a new video and stop others
      setPlayingVideoId(videoId);
      setIsPlaying(true);
    }
  };

  
  // 🔹 Show message if there are no videos
  if (!loading && videos.length === 0) {
    return (
      <Box sx={{ p: 3, textAlign: "center" }}>
        <Typography variant="h6" color="error">   No videos available</Typography>
        <Typography variant="body2" color="textSecondary"> Please check back later or upload some videos. </Typography>
      </Box>
    );
  }

  if (loading) {
    return (
      <Grid container spacing={3} sx={{ p: 2 }}>
        {Array.from({ length: 6 }).map((_, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Paper sx={{ borderRadius: "10px", overflow: "hidden" }}>
              <Skeleton variant="rectangular" width="100%" height={250} />
              <Stack direction="row" justifyContent="space-around" sx={{ p: 1 }}>
                <Skeleton variant="text" width="30%" />
                <Skeleton variant="text" width="30%" />
                <Skeleton variant="text" width="30%" />
                <Skeleton variant="text" width="30%" />
              </Stack>
            </Paper>
          </Grid>
        ))}
      </Grid>
    );
  }
  return (
    <Grid container spacing={3} sx={{ p: 2 }}>
      {videos.map((video) => (
        <Grid item xs={12} sm={6} md={4} key={video.id}>
          <Paper sx={{ borderRadius: "10px", overflow: "hidden"}}>
            <Box
              sx={{
                position: "relative",
                width: "100%",
                height: 200,
                backgroundColor: "#000",
                cursor: "pointer"}}
                onClick={() => handlePlayPause(video.id)} // Toggle play/pause on video click
            >
              {playingVideoId === video.id ? (
                <ReactPlayer
                  url={video.iosUrl}
                  width="100%"
                  height="100%"
                  playing={isPlaying} // Play/Pause state
                  controls={false} // Hides default ReactPlayer controls
                  config={{ file: { attributes: { preload: "metadata" }} }}
                />
              ) : (
                <>
                  {/* Display Thumbnail */}
                  <CardMedia
                    component="img"
                    src={video.thumbUrl}
                    alt="Video Thumbnail"
                    sx={{ width: "100%", height: "100%", objectFit: "cover"}}
                  />
                  {/* Play Button Overlay */}
                  {/* <IconButton
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent triggering video click
                      handlePlayPause(video.id);
                    }}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      color: "#fff",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.8)" },
                    }}
                  >
                    <PlayArrowIcon sx={{ fontSize: 40 }} />
                  </IconButton> */}
                </>
              )}
            </Box>
            {/* Video Stats and Actions */}
            <Stack direction="row" justifyContent="space-around" sx={{ p: 1 }}>
              <Stack direction="column" alignItems="center">
                <IconButton>
                  <FavoriteBorderIcon  />
                </IconButton>
                <Typography variant="caption">{video?.likes || 0}</Typography>
              </Stack>

              <Stack direction="column" alignItems="center">
                <IconButton>
                  <ChatBubbleOutlineIcon />
                </IconButton>
                <Typography variant="caption">{video?.comments || 0}</Typography>
              </Stack>

              <Stack direction="column" alignItems="center">
                <IconButton>
                  <ShareIcon />
                </IconButton>
                <Typography variant="caption">{video?.shares || 0}</Typography>
              </Stack>

              <Stack direction="column" alignItems="center">
                <IconButton
                  sx={{  mt: 1, backgroundColor: "rgba(0, 0, 0, 0.6)",
                   "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.8)" }, color: "#FFF"}}
                  onClick={(e) => {e.stopPropagation();  handlePlayPause(video.id)}}>
                   {playingVideoId === video.id && isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                </IconButton>
                <Typography variant="caption"></Typography>
              </Stack>

              {/* Uncomment to enable download functionality */}
              {/* <Stack direction="column" alignItems="center">
                <IconButton
                  sx={{ color: "#000" }}
                  component="a"
                  href={video.downloadUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <DownloadIcon />
                </IconButton>
                <Typography variant="caption">Download</Typography>
              </Stack> */}
            </Stack>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default VideoGrid;