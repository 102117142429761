import { useState, useEffect, useRef } from "react";
import { getResponse } from "../../../utils/axios_api_utils";

const useStats = () => {
  const [statsData, setStatsData] = useState({
    totalUsers: 0,
    totalVideos: 0,
  });
  const [loading, setLoading] = useState(true);
  const isFetched = useRef(false); // To track if data has been fetched

  useEffect(() => {
    // Avoid duplicate API calls
    if (isFetched.current) return; // Prevent duplicate fetch
    isFetched.current = true;

    const fetchStats = async () => {
      setLoading(true);
      try {
        const response = await getResponse("base", "dashboard/summary");  
        if (response.status === 200) {
          const totalUsers = response.data?.totalUsers?.[0]?.total_users || 0;
          const totalVideos = response.data?.totalVideos?.[0]?.total_videos || 0;
          setStatsData({ totalUsers, totalVideos });
        } else {
          console.error(`Failed to fetch stats. Status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching stats:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();

  }, []);

  return { statsData, loading };
};

export default useStats;




// import { useState, useEffect, useRef } from "react";
// import { getResponse } from "../../../utils/axios_api_utils";

// const useStats = () => {
//   const [statsData, setStatsData] = useState({ totalUsers: 0, totalVideos: 0 });
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const cacheRef = useRef(null); // Cache API response

//   const fetchStats = async () => {
//     setLoading(true);
//     setError(null);
//     try {
//       const endpoint = "dashboard/summary";
//       if (cacheRef.current) {
//         // Use cached data if available
//         setStatsData(cacheRef.current);
//         setLoading(false);
//         return;
//       }
//       const response = await getResponse(endpoint);
//       if (response.status === 200) {
//         const newStats = {
//           totalUsers: response.data?.totalUsers[0]?.total_users || 0,
//           totalVideos: response.data?.totalVideos[0]?.total_videos || 0,
//         };
//         cacheRef.current = newStats; // Cache the response
//         setStatsData(newStats);
//       } else {
//         throw new Error(`Error: ${response.status}`);
//       }
//     } catch (error) {
//       console.error("Error fetching stats:", error);
//       setError("Failed to load data. Please try again later.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     const debounceTimeout = setTimeout(() => fetchStats(), 300); // Debounced call
//     return () => clearTimeout(debounceTimeout); // Cleanup on unmount
//   }, []); // Empty array ensures API is called once

//   return { statsData, loading, error };
// };

// export default useStats;
