import { useState, useEffect, useRef, useCallback } from "react";
import { toast } from "react-toastify";
import { getResponse } from "../../../utils/axios_api_utils";

const VIDEO_BASE_URL = process.env.REACT_APP_S3_VIDEO_BASE_URL || "";

export const useVideos = () => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nextKey, setNextKey] = useState(null);
  const [prevState, setPrevState] = useState([]);
  const [isNext, setIsNext] = useState(true);
  const [uploading, setUploading] = useState(false);
  const videoRefs = useRef([]);


  const fetchVideos = useCallback(async (pageKey = null) => {
    setLoading(true);
    try {
      const response = await getResponse("videoUnassigned", "list/video/unassigned", {
        params: { pageSize: 6, nextKey: pageKey ? encodeURIComponent(pageKey) : null },
      });

      if (response.status===200) {
        const { list, isNext: hasNext, nextkey } = response.data;
        setVideos(list);
        setIsNext(hasNext);
        setNextKey(nextkey);
        if (!pageKey) setPrevState([]); // Reset prevState on fresh fetch
      } else {
        throw new Error("Invalid response data");
      }
    } catch (error) {
      console.error("Error fetching videos:", error);
      toast.error("Failed to load videos. Please try again later.");
    } finally {
      setLoading(false);
    }
  }, []);

  const handleNextPage = () => {
    if (isNext && nextKey) {
      setPrevState((prev) => [...prev, { videos, nextKey }]);
      fetchVideos(nextKey);
    }
  };

  const handlePrevPage = () => {
    if (prevState.length > 0) {
      const lastState = prevState[prevState.length - 1]; // Get last state
      setVideos(lastState.videos);
      setNextKey(lastState.nextKey);
      setPrevState((prev) => prev.slice(0, -1)); // Remove last state immutably
    }
  };

  useEffect(() => {
    fetchVideos();
  }, [fetchVideos]); // Added fetchVideos as a dependency to avoid stale closures

  const handleVideoPlay = (index) => {
    videoRefs.current.forEach((video, i) => {
      if (video && i !== index) video.pause();
    });
  };

  const handleUpload = async () => {
    setUploading(true);
    try {
      const response = await getResponse("media", "start");
      if (response?.status === 200 && response?.data?.message) {
        toast.success(response.data.message || "Video published successfully.");
        fetchVideos(); // Refresh video list after a successful upload
      } else {
        throw new Error("Invalid response from upload API");
      }
    } catch (error) {
      console.error("Error uploading video:", error);
      toast.error("Failed to upload video. Please try again.");
    } finally {
      setUploading(false);
    }
  };

  return {
    videos,
    loading,
    isNext,
    handleNextPage,
    handlePrevPage,
    handleUpload,
    uploading,
    handleVideoPlay,
    videoRefs,
    VIDEO_BASE_URL,
  };
};
