

import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Box, Button, Typography } from "@mui/material";
import { FiPlus } from "react-icons/fi";
import { Link } from "react-router-dom";
import Table from "../../components/common/table/Table";
import axios from "axios";

// API URL for fetching unpublished videos
const UNPUBLISH_VIDEOS_API_URL = "https://m4h5sy01i9.execute-api.ap-south-1.amazonaws.com/dev/list/unpublish/videos";

// Styled wrapper for consistent layout.
const ComponentWrapper = styled(Box)({
  marginTop: "10px",
  paddingBottom: "10px",
});

/**
 * Component to display unpublished videos table.
 */
const VideoPublished = () => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("token");

  // Fetch unpublished videos from API
  useEffect(() => {
    const fetchUnpublishVideos = async () => {
      setLoading(true);
      try {
        const response = await axios.get(UNPUBLISH_VIDEOS_API_URL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          setVideos(response.data || []);
        } else {
          throw new Error(`API responded with status ${response.status}`);
        }
      } catch (error) {
        console.error("Failed to fetch unpublished videos:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUnpublishVideos();
  }, [token]);

  // Common function to format date
const formatDate = (timestamp) => {
    return new Date(timestamp).toLocaleString("en-IN", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZone: "Asia/Kolkata",
    });
  }
  // Define table columns for unpublished videos.
  const videoColumns = [
  
    {
      Header: "Fist Name",
      accessorKey: "firstName",
    },
    {
        Header: "Last Name",
        accessorKey: "lastName",
      },
    {
      Header: "User Name",
      accessorKey: "userName",
    },
    {
      Header: "Likes",
      accessorKey: "likes",
    },
    {
        Header: "Comment",
        accessorKey: "comments",
      },
    {
      Header: "Views",
      accessorKey: "views",
    },
    {
        Header: "Shares",
        accessorKey: "shares",
      },
      {
        Header: "Downloads",
        accessorKey: "downloads",
      },
      {
        Header: "Created At",
        accessorKey: "createdAt",
        Cell: ({ cell }) => formatDate(cell.getValue()),
      },
      {
        Header: "Updated At",
        accessorKey: "updatedAt",
        Cell: ({ cell }) => formatDate(cell.getValue()),
      },
      {
        accessorKey: "status",
        header: "Status",
        Cell: ({ cell }) => (
          <span
            className={`status ${cell.getValue() === 1 ? "active" : "inactive"}`}
          >
            {cell.getValue() === 1 ? "Active" : "Inactive"}
          </span>
        ),
      },
  ];

  return (
    <Box sx={{ pt: "60px", pb: "20px" }}>
      <ComponentWrapper>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          my:4
        }}
      >
        <Typography variant="h6">Published Video</Typography>
       
      </Box>
       
          {/* <Table
            isLoading={loading}
            data={videos}
            fields={videoColumns}
            numberOfRows={7}
            enableTopToolBar={false}
            enableBottomToolBar={false}
            enablePagination={true}
            enableRowSelection={false}
            enableColumnFilters={false}
            enableEditing={false}
            enableColumnDragging={false}
          /> */}
       
      </ComponentWrapper>
    </Box>
  );
};

export default VideoPublished;
