import React, { memo } from "react";
import { Box, Button, Typography } from "@mui/material";
import { FiPlus } from "react-icons/fi";
import { Link } from "react-router-dom";
import Table from "../../components/common/table/Table";
import { usersColumns } from "../../data/users";
import useUsers from "./hook/useUsers"; // Import the custom hook

const Users = memo(() => {

  // Destructure values and functions from the custom hook
  const {
    users,
    currentPage,
    totalRecords,
    pageSize,
    loading,
    searchTerm,
    handlePageChange,
    handlePageSizeChange,
    handleGlobalFilterChange,
    
  } = useUsers(); // Use the custom hook

  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
        <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "16px",
        }}
      >
        <Typography variant="h6">Users</Typography>
        <Link to="/users/create-user" style={{ textDecoration: "none" }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<FiPlus />}
            sx={{ borderRadius: "20px" }}
          >
            create User
          </Button>
        </Link>
      </Box>
     
      <Table
        data={users}
        fields={usersColumns}
        currentPage={currentPage}
        totalRecords={totalRecords}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        pageSize={pageSize}
        onGlobalFilterChange={handleGlobalFilterChange}
        globalFilter={searchTerm}
        enableGlobalFilter
        isLoading={loading}
      />
    </Box>
  );
});

export default Users;
